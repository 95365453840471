const PACKAGE_OPTIONS = [
  { value: '3d_modelling', label: '3D Modelling' },
  { value: '3d_laser_scanning_survey', label: '3d Laser Scanning Survey' },
  { value: 'above_ground_drainage', label: 'Above Ground Drainage' },
  { value: 'abseilers_/_rope_access', label: 'Abseilers / Rope Access' },
  {
    value: 'access_/_temporary_works_/_fall_restraint',
    label: 'Access / Temporary Works / Fall Restraint',
  },
  { value: 'access_controls', label: 'Access Controls' },
  { value: 'access_hatches', label: 'Access Hatches' },
  { value: 'access_platforms', label: 'Access Platforms' },
  { value: 'acoustic_/_air_barriers', label: 'Acoustic / Air Barriers' },
  { value: 'acoustic_ceilings', label: 'Acoustic Ceilings' },
  { value: 'acoustic_consultant', label: 'Acoustic Consultant' },
  { value: 'acoustic_enclosures', label: 'Acoustic Enclosures' },
  { value: 'acoustic_flooring', label: 'Acoustic Flooring' },
  { value: 'acoustic_louvres', label: 'Acoustic Louvres' },
  { value: 'acoustic_panelling', label: 'Acoustic Panelling' },
  { value: 'aerial_photography', label: 'Aerial Photography' },
  { value: 'aerials_/_irs', label: 'Aerials / IRS' },
  { value: 'aggregates', label: 'Aggregates' },
  { value: 'air_conditioning', label: 'Air Conditioning' },
  { value: 'air_source_heat_pumps', label: 'Air Source Heat Pumps' },
  { value: 'air_testing', label: 'Air Testing' },
  { value: 'aluminium_capping', label: 'Aluminium Capping' },
  { value: 'aluminium_cladding', label: 'Aluminium Cladding' },
  { value: 'aluminium_doors', label: 'Aluminium Doors' },
  { value: 'aluminium_windows', label: 'Aluminium Windows' },
  { value: 'appliances', label: 'Appliances' },
  { value: 'approved_inspector', label: 'Approved Inspector' },
  { value: 'archaeologist', label: 'Archaeologist' },
  { value: 'architect', label: 'Architect' },
  {
    value: 'architectural_/_bespoke_stairs',
    label: 'Architectural / Bespoke Stairs',
  },
  {
    value: 'architectural_glazing_/_glass',
    label: 'Architectural Glazing / Glass',
  },
  { value: 'architectural_hardware', label: 'Architectural Hardware' },
  {
    value: 'architectural_metalwork_/_steelwork',
    label: 'Architectural Metalwork / Steelwork',
  },
  { value: 'architectural_photography', label: 'Architectural Photography' },
  {
    value: 'architectural_precast_concrete',
    label: 'Architectural Precast Concrete',
  },
  {
    value: 'art_work_/_murals_/_trompe_l_oeil',
    label: 'Art work / Murals / Trompe L Oeil',
  },
  { value: 'asbestos_encapsulation', label: 'Asbestos Encapsulation' },
  { value: 'asbestos_removal', label: 'Asbestos Removal' },
  { value: 'asbestos_surveyor', label: 'Asbestos Surveyor' },
  { value: 'asphalt_roofing', label: 'Asphalt Roofing' },
  { value: 'audio_visual', label: 'Audio Visual' },
  { value: 'auto_louvre', label: 'Auto Louvre' },
  { value: 'automated_doors', label: 'Automated Doors' },
  { value: 'automatic_gates', label: 'Automatic Gates' },
  {
    value: 'automatic_opening_vents_(aov)',
    label: 'Automatic Opening Vents (AOV)',
  },
  { value: 'bim_consultant', label: 'BIM Consultant' },
  { value: 'bmu_providers', label: 'BMU Providers' },
  { value: 'basement_conversion', label: 'Basement Conversion' },
  { value: 'basements', label: 'Basements' },
  { value: 'bat_surveys', label: 'Bat Surveys' },
  { value: 'bathrooms', label: 'Bathrooms' },
  { value: 'below_ground_drainage', label: 'Below Ground Drainage' },
  {
    value: 'bespoke_architectural_timber_structures',
    label: 'Bespoke Architectural Timber Structures',
  },
  { value: 'bespoke_gates', label: 'Bespoke Gates' },
  { value: 'bespoke_glazing_/_glass', label: 'Bespoke Glazing / Glass' },
  {
    value: 'bespoke_metalwork_/_steelwork',
    label: 'Bespoke Metalwork / Steelwork',
  },
  { value: 'bespoke_signage', label: 'Bespoke Signage' },
  { value: 'bi-folding_doors', label: 'Bi-folding Doors' },
  { value: 'bicycle/bike_rack', label: 'Bicycle/Bike Rack' },
  { value: 'biomass', label: 'Biomass' },
  { value: 'bird_control_/_protection', label: 'Bird Control / Protection' },
  { value: 'bird_deterrent_/_control', label: 'Bird Deterrent / Control' },
  { value: 'blind_automation', label: 'Blind Automation' },
  { value: 'blind_controls', label: 'Blind Controls' },
  { value: 'blinds', label: 'Blinds' },
  { value: 'block_and_beam', label: 'Block and Beam' },
  { value: 'blue_roofing', label: 'Blue Roofing' },
  { value: 'boiler_services', label: 'Boiler Services' },
  { value: 'brick_/_facade_cleaning', label: 'Brick / Facade Cleaning' },
  { value: 'brick_/_structural_repairs', label: 'Brick / Structural Repairs' },
  { value: 'brick_slips', label: 'Brick Slips' },
  { value: 'brick_work_/_block_work', label: 'Brick Work / Block Work' },
  { value: 'bridges', label: 'Bridges' },
  { value: 'brise_soleil', label: 'Brise Soleil' },
  { value: 'builderswork_contractor', label: 'Builderswork Contractor' },
  { value: 'building_contols', label: 'Building Contols' },
  {
    value: 'building_management_systems_(bms)',
    label: 'Building Management Systems (BMS)',
  },
  { value: 'building_materials', label: 'Building Materials' },
  { value: 'building_services_engineer', label: 'Building Services Engineer' },
  { value: 'building_surveyor', label: 'Building Surveyor' },
  { value: 'cad_technician', label: 'CAD Technician' },
  { value: 'cdm_services_and_planning', label: 'CDM Services and Planning' },
  { value: 'cabinetry', label: 'Cabinetry' },
  { value: 'canopies', label: 'Canopies' },
  { value: 'carpentry_-_first_fix', label: 'Carpentry - First Fix' },
  { value: 'carpentry_-_second_fix', label: 'Carpentry - Second Fix' },
  { value: 'carpeting', label: 'Carpeting' },
  { value: 'casement_window', label: 'Casement Window' },
  { value: 'cast_iron', label: 'Cast Iron' },
  { value: 'cast_stone', label: 'Cast Stone' },
  { value: 'cavity_wall_insulation', label: 'Cavity Wall Insulation' },
  {
    value: 'cavity_wall_and_insulation_extraction',
    label: 'Cavity Wall and Insulation Extraction',
  },
  { value: 'ceilings', label: 'Ceilings' },
  { value: 'chimneys_/_flues', label: 'Chimneys / Flues' },
  { value: 'civils_and_infrastructure', label: 'Civils and Infrastructure' },
  {
    value: 'cladding_/_facades_/_envelope',
    label: 'Cladding / Facades / Envelope',
  },
  { value: 'cladding_paint_repairs', label: 'Cladding Paint Repairs' },
  { value: 'cleaning_contractors', label: 'Cleaning Contractors' },
  {
    value: 'combined_heat_and_power_(chp)',
    label: 'Combined Heat and Power (CHP)',
  },
  { value: 'commercial_management', label: 'Commercial Management' },
  { value: 'composite_doors', label: 'Composite Doors' },
  { value: 'composite_windows', label: 'Composite Windows' },
  { value: 'concrete', label: 'Concrete' },
  { value: 'concrete_pumping', label: 'Concrete Pumping' },
  { value: 'concrete_repairs', label: 'Concrete Repairs' },
  { value: 'construction_flags', label: 'Construction Flags' },
  { value: 'construction_photography', label: 'Construction Photography' },
  {
    value: 'consultants_/_surveyors_/_surveys',
    label: 'Consultants / Surveyors / Surveys',
  },
  {
    value: 'contaminated_ground_remediation',
    label: 'Contaminated Ground Remediation',
  },
  { value: 'contiguous_piling', label: 'Contiguous Piling' },
  {
    value: 'continuous_flight_auger_(cfa)_piling',
    label: 'Continuous Flight Auger (CFA) Piling',
  },
  { value: 'contract_flooring', label: 'Contract Flooring' },
  { value: 'crane_hire', label: 'Crane Hire' },
  { value: 'curtains', label: 'Curtains' },
  { value: 'damp_proofing', label: 'Damp Proofing' },
  { value: 'data', label: 'Data' },
  { value: 'decking', label: 'Decking' },
  { value: 'decorative_surfacing', label: 'Decorative Surfacing' },
  { value: 'demolition_/_disposal', label: 'Demolition / Disposal' },
  { value: 'demolition_/_strip_out', label: 'Demolition / Strip out' },
  { value: 'diamond_drilling_/_cutting', label: 'Diamond Drilling / Cutting' },
  { value: 'diffusers', label: 'Diffusers' },
  { value: 'disposal_/_waste_removal', label: 'Disposal / Waste Removal' },
  { value: 'dispute_management', label: 'Dispute Management' },
  { value: 'door_entry', label: 'Door Entry' },
  {
    value: 'door_refurbishment_/_stripping',
    label: 'Door Refurbishment / Stripping',
  },
  { value: 'doors_/_windows_/_gates', label: 'Doors / Windows / Gates' },
  { value: 'drainage_cleaning', label: 'Drainage Cleaning' },
  { value: 'drainage_and_service_ducts', label: 'Drainage and Service Ducts' },
  { value: 'draughtsman_/_designer', label: 'Draughtsman / Designer' },
  { value: 'driven_/_displacement_pile', label: 'Driven / Displacement Pile' },
  { value: 'driveways', label: 'Driveways' },
  { value: 'drone_photography', label: 'Drone Photography' },
  { value: 'dry_lining_and_partitions', label: 'Dry Lining and Partitions' },
  { value: 'dry_riser_/_wet_riser', label: 'Dry Riser / Wet Riser' },
  { value: 'dry_stone_walling', label: 'Dry Stone Walling' },
  { value: 'dry-lining', label: 'Dry-Lining' },
  { value: 'ductwork', label: 'Ductwork' },
  { value: 'etfe_systems', label: 'ETFE Systems' },
  { value: 'ecological_appraisals', label: 'Ecological Appraisals' },
  {
    value: 'electric_underfloor_heating',
    label: 'Electric Underfloor Heating',
  },
  { value: 'electric_vehicle_charging', label: 'Electric Vehicle Charging' },
  { value: 'electrical_contractor', label: 'Electrical Contractor' },
  {
    value: 'electrical_mains_installations',
    label: 'Electrical Mains Installations',
  },
  {
    value: 'electrical_vehicle_charging_points',
    label: 'Electrical Vehicle Charging Points',
  },
  { value: 'electrostatic_air_cleaners', label: 'Electrostatic Air Cleaners' },
  { value: 'enabling_works', label: 'Enabling Works' },
  { value: 'entertainment_/_cinema', label: 'Entertainment / Cinema' },
  { value: 'entrance_matting', label: 'Entrance Matting' },
  { value: 'escalators_/_travellators', label: 'Escalators / Travellators' },
  { value: 'excavation', label: 'Excavation' },
  { value: 'extensions', label: 'Extensions' },
  { value: 'external_/_landscaping', label: 'External / Landscaping' },
  { value: 'external_green_screen', label: 'External Green Screen' },
  { value: 'external_wall_insulation', label: 'External Wall Insulation' },
  { value: 'fabric_cladding_systems', label: 'Fabric Cladding Systems' },
  {
    value: 'facade_repairs_/_restoration',
    label: 'Facade Repairs / Restoration',
  },
  {
    value: 'fall_restraint_/_fall_arrest',
    label: 'Fall Restraint / Fall Arrest',
  },
  {
    value: 'falsework_/_temporary_works',
    label: 'Falsework / Temporary Works',
  },
  { value: 'fan_filter_units', label: 'Fan Filter Units' },
  { value: 'fancoils', label: 'Fancoils' },
  { value: 'fencing', label: 'Fencing' },
  { value: 'fibrous_plaster', label: 'Fibrous Plaster' },
  { value: 'fire', label: 'Fire' },
  { value: 'fire_detection_/_alarm', label: 'Fire Detection / Alarm' },
  { value: 'fire_doors', label: 'Fire Doors' },
  { value: 'fire_places_/_surrounds', label: 'Fire Places / Surrounds' },
  {
    value: 'fire_proofing_/_passive_fire_protection',
    label: 'Fire Proofing / Passive Fire Protection',
  },
  { value: 'fire_rated_glazing', label: 'Fire Rated Glazing' },
  { value: 'fire_safety_consultant', label: 'Fire Safety Consultant' },
  {
    value: 'fire_stopping_/_fire_barriers',
    label: 'Fire Stopping / Fire Barriers',
  },
  { value: 'fire_and_smoke_dampers', label: 'Fire and Smoke Dampers' },
  { value: 'fit-out_and_interiors', label: 'Fit-Out and Interiors' },
  { value: 'flood_water_management', label: 'Flood Water Management' },
  { value: 'floor_sanding', label: 'Floor Sanding' },
  { value: 'floor_and_wall_tiling', label: 'Floor and Wall Tiling' },
  { value: 'flooring', label: 'Flooring' },
  { value: 'food_lift_/_dumb_waiter', label: 'Food Lift / Dumb Waiter' },
  { value: 'forecourt_structures', label: 'Forecourt Structures' },
  { value: 'formwork', label: 'Formwork' },
  { value: 'foul_drainage_pump_systems', label: 'Foul Drainage Pump Systems' },
  { value: 'foundations', label: 'Foundations' },
  { value: 'french_polishing', label: 'French Polishing' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'grc_cladding', label: 'GRC Cladding' },
  { value: 'grp_/_fibreglass', label: 'GRP / Fibreglass' },
  { value: 'grp_cladding', label: 'GRP Cladding' },
  { value: 'grp_structures', label: 'GRP Structures' },
  { value: 'gantries_/_walkways', label: 'Gantries / Walkways' },
  { value: 'garage_doors', label: 'Garage Doors' },
  { value: 'garden_furniture', label: 'Garden Furniture' },
  { value: 'gas_membranes', label: 'Gas Membranes' },
  { value: 'gas_services', label: 'Gas Services' },
  { value: 'gas_verification_company', label: 'Gas Verification Company' },
  { value: 'general_building', label: 'General Building' },
  {
    value: 'geotechnical_/_environmental',
    label: 'Geotechnical / Environmental',
  },
  { value: 'glass_balconies', label: 'Glass Balconies' },
  {
    value: 'glass_balustrades_/_handrails',
    label: 'Glass Balustrades / Handrails',
  },
  { value: 'glass_partitions', label: 'Glass Partitions' },
  { value: 'glass_repairs', label: 'Glass Repairs' },
  { value: 'glazing_/_glass', label: 'Glazing / Glass' },
  { value: 'goods_lift', label: 'Goods Lift' },
  { value: 'green_energy_consultant', label: 'Green Energy Consultant' },
  { value: 'green_roofing', label: 'Green Roofing' },
  { value: 'green_technologies', label: 'Green Technologies' },
  { value: 'green_wall', label: 'Green Wall' },
  { value: 'grilles', label: 'Grilles' },
  {
    value: 'ground_contamination_surveys',
    label: 'Ground Contamination Surveys',
  },
  {
    value: 'ground_gas_training_seminars',
    label: 'Ground Gas Training Seminars',
  },
  { value: 'ground_source_heat_pumps', label: 'Ground Source Heat Pumps' },
  { value: 'ground_work', label: 'Ground Work' },
  {
    value: 'ground_works_/_foundations_/_drainage',
    label: 'Ground Works / Foundations / Drainage',
  },
  {
    value: 'guardrails_/_edge_protection',
    label: 'Guardrails / Edge Protection',
  },
  { value: 'guttering/rainwater_goods', label: 'Guttering/Rainwater Goods' },
  {
    value: 'hv/lv_supply_&_distribution',
    label: 'HV/LV Supply & Distribution',
  },
  {
    value: 'hvac_-_heat_recovery_systems',
    label: 'HVAC - Heat Recovery Systems',
  },
  { value: 'hard_landscaping', label: 'Hard Landscaping' },
  {
    value: 'health_and_safety_consultant',
    label: 'Health and Safety Consultant',
  },
  { value: 'health_and_safety_signs', label: 'Health and Safety Signs' },
  { value: 'heating_controls', label: 'Heating Controls' },
  { value: 'heating_solutions', label: 'Heating Solutions' },
  {
    value: 'historic_building_restoration',
    label: 'Historic Building Restoration',
  },
  {
    value: 'hoarding_/_hoarding_graphics',
    label: 'Hoarding / Hoarding Graphics',
  },
  { value: 'hydraulic_lifts', label: 'Hydraulic Lifts' },
  { value: 'hygienic_doors', label: 'Hygienic Doors' },
  { value: 'hygienic_wall_cladding', label: 'Hygienic Wall Cladding' },
  { value: 'ips_toilet_panels', label: 'IPS Toilet Panels' },
  { value: 'illuminated_signs', label: 'Illuminated Signs' },
  {
    value: 'independent_connection_provider_(icp)',
    label: 'Independent Connection Provider (ICP)',
  },
  {
    value: 'independent_material_testing',
    label: 'Independent Material Testing',
  },
  { value: 'industrial_/_roller_doors', label: 'Industrial / Roller Doors' },
  { value: 'insitu_concrete', label: 'Insitu Concrete' },
  { value: 'insulating_materials', label: 'Insulating Materials' },
  { value: 'internal_partitions', label: 'Internal Partitions' },
  { value: 'intumescent_coating', label: 'Intumescent Coating' },
  {
    value: 'invasive_plant_species_management',
    label: 'Invasive Plant Species Management',
  },
  { value: 'ironmongery', label: 'Ironmongery' },
  { value: 'irrigation_systems', label: 'Irrigation Systems' },
  { value: 'joinery', label: 'Joinery' },
  { value: 'kitchens', label: 'Kitchens' },
  { value: 'labour_agency', label: 'Labour Agency' },
  { value: 'ladders', label: 'Ladders' },
  {
    value: 'lagging_/_thermal_insulation',
    label: 'Lagging / Thermal Insulation',
  },
  { value: 'land_surveys', label: 'Land Surveys' },
  { value: 'landscape_design', label: 'Landscape Design' },
  { value: 'lath_and_plaster', label: 'Lath and Plaster' },
  { value: 'lead_paint_removal', label: 'Lead Paint Removal' },
  { value: 'lead_roofing', label: 'Lead Roofing' },
  { value: 'lift_removal', label: 'Lift Removal' },
  { value: 'lifts', label: 'Lifts' },
  { value: 'lifts_/_escalators', label: 'Lifts / Escalators' },
  { value: 'lighting', label: 'Lighting' },
  { value: 'lightning_protection', label: 'Lightning Protection' },
  { value: 'lime_mortar_pointing', label: 'Lime Mortar Pointing' },
  { value: 'lime_plastering', label: 'Lime Plastering' },
  { value: 'linen_chute', label: 'Linen Chute' },
  { value: 'lintels', label: 'Lintels' },
  {
    value: 'liquid_applied_waterproofing',
    label: 'Liquid Applied Waterproofing',
  },
  { value: 'load_bearing_piling', label: 'Load Bearing Piling' },
  { value: 'loft_conversions', label: 'Loft Conversions' },
  { value: 'logistics_planner', label: 'Logistics Planner' },
  { value: 'louvres', label: 'Louvres' },
  { value: 'making_good', label: 'Making Good' },
  { value: 'marble', label: 'Marble' },
  { value: 'masonry_/_brickwork', label: 'Masonry / Brickwork' },
  { value: 'masonry_construction', label: 'Masonry Construction' },
  { value: 'mast_photography', label: 'Mast Photography' },
  { value: 'mast_climbers', label: 'Mast climbers' },
  { value: 'measured_building_survey', label: 'Measured Building Survey' },
  { value: 'mechanical_contractor', label: 'Mechanical Contractor' },
  {
    value: 'mechanical_and_electrical_contractor',
    label: 'Mechanical and Electrical Contractor',
  },
  {
    value: 'mechanical_and_electrical_engineer',
    label: 'Mechanical and Electrical Engineer',
  },
  {
    value: 'mechanical,_electrical_and_services',
    label: 'Mechanical, Electrical and Services',
  },
  { value: 'metal_decking', label: 'Metal Decking' },
  { value: 'metal_polishing', label: 'Metal Polishing' },
  { value: 'metal_roofing', label: 'Metal Roofing' },
  {
    value: 'metering_management_services',
    label: 'Metering Management Services',
  },
  { value: 'mezzanine_floors', label: 'Mezzanine Floors' },
  { value: 'mini_piling', label: 'Mini Piling' },
  { value: 'mirrors', label: 'Mirrors' },
  { value: 'mosaic_tiling', label: 'Mosaic Tiling' },
  { value: 'motorised_blinds', label: 'Motorised Blinds' },
  { value: 'motorway_communications', label: 'Motorway Communications' },
  { value: 'muck_away', label: 'Muck Away' },
  { value: 'multi-trade_contractor', label: 'Multi-Trade Contractor' },
  { value: 'open_augered_piling', label: 'Open Augered Piling' },
  { value: 'openers_/_hatches', label: 'Openers / Hatches' },
  {
    value: 'operation_and_maintenance_manuals',
    label: 'Operation and Maintenance Manuals',
  },
  { value: 'outdoor_kitchen', label: 'Outdoor Kitchen' },
  { value: 'pqs_/_quantity_surveyors', label: 'PQS / Quantity Surveyors' },
  { value: 'padstones', label: 'Padstones' },
  { value: 'painting_/_decorating', label: 'Painting / Decorating' },
  { value: 'parquet_flooring', label: 'Parquet Flooring' },
  { value: 'pavements', label: 'Pavements' },
  { value: 'paving', label: 'Paving' },
  { value: 'pedestrian_guardrails', label: 'Pedestrian Guardrails' },
  { value: 'pergola', label: 'Pergola' },
  { value: 'pest_control', label: 'Pest Control' },
  { value: 'pharmaceutical_cleanrooms', label: 'Pharmaceutical Cleanrooms' },
  { value: 'photovoltaic_(pv)_panels', label: 'Photovoltaic (PV) Panels' },
  {
    value: 'planning_and_neighbouring_applications',
    label: 'Planning and Neighbouring Applications',
  },
  { value: 'planning_and_programming', label: 'Planning and Programming' },
  { value: 'planters', label: 'Planters' },
  { value: 'plastering', label: 'Plastering' },
  { value: 'platform_lifts', label: 'Platform Lifts' },
  { value: 'play_areas', label: 'Play Areas' },
  { value: 'plumbing_and_heating', label: 'Plumbing and Heating' },
  { value: 'polished_concrete_flooring', label: 'Polished Concrete Flooring' },
  { value: 'polished_plaster', label: 'Polished Plaster' },
  { value: 'polyurea_roof_coatings', label: 'Polyurea Roof Coatings' },
  { value: 'posi_joists', label: 'Posi Joists' },
  { value: 'precast_concrete_cladding', label: 'Precast Concrete Cladding' },
  { value: 'precast_concrete_drainage', label: 'Precast Concrete Drainage' },
  { value: 'precast_concrete_flooring', label: 'Precast Concrete Flooring' },
  {
    value: 'precast_concrete_insulated_flooring',
    label: 'Precast Concrete Insulated Flooring',
  },
  {
    value: 'precast_concrete_lift_shafts',
    label: 'Precast Concrete Lift Shafts',
  },
  { value: 'precast_concrete_stairs', label: 'Precast Concrete Stairs' },
  {
    value: 'precast_concrete_structures',
    label: 'Precast Concrete Structures',
  },
  {
    value: 'principal_/_main_contractor',
    label: 'Principal / Main Contractor',
  },
  { value: 'principle_designer', label: 'Principle Designer' },
  { value: 'project_managers', label: 'Project Managers' },
  { value: 'protective_coatings', label: 'Protective Coatings' },
  {
    value: 'public_health_/_disposal_systems',
    label: 'Public Health / Disposal Systems',
  },
  { value: 'pump_systems', label: 'Pump Systems' },
  { value: 'radiators', label: 'Radiators' },
  { value: 'rainscreen_cladding', label: 'Rainscreen Cladding' },
  { value: 'raised_access_flooring', label: 'Raised Access Flooring' },
  { value: 'refurbishment_/_fit_out', label: 'Refurbishment / Fit Out' },
  { value: 'render', label: 'Render' },
  {
    value: 'repair_/_restoration_/_treatments',
    label: 'Repair / Restoration / Treatments',
  },
  { value: 'repointing', label: 'Repointing' },
  { value: 'resin_flooring', label: 'Resin Flooring' },
  { value: 'revolving_doors', label: 'Revolving Doors' },
  { value: 'road_markings_and_studs', label: 'Road Markings and Studs' },
  { value: 'road_surfacing', label: 'Road Surfacing' },
  { value: 'roof_lantern', label: 'Roof Lantern' },
  { value: 'roof_trusses', label: 'Roof Trusses' },
  { value: 'roofing', label: 'Roofing' },
  { value: 'rooflight', label: 'Rooflight' },
  { value: 'room_in_a_roof_insulation', label: 'Room In A Roof Insulation' },
  { value: 'roughcasting', label: 'Roughcasting' },
  {
    value: 'sap_-_air_tightness_testing',
    label: 'SAP - Air Tightness Testing',
  },
  { value: 'suds', label: 'SUDS' },
  { value: 'safe_installation', label: 'Safe Installation' },
  { value: 'safe_removal', label: 'Safe Removal' },
  { value: 'safety_barriers', label: 'Safety Barriers' },
  { value: 'sanitaryware', label: 'Sanitaryware' },
  { value: 'sarnafill_roofing_systems', label: 'Sarnafill Roofing Systems' },
  { value: 'sash_windows', label: 'Sash Windows' },
  { value: 'scaffold', label: 'Scaffold' },
  { value: 'screed_pump_hire', label: 'Screed Pump Hire' },
  { value: 'screeding', label: 'Screeding' },
  { value: 'screw_/_helical_piles', label: 'Screw / Helical Piles' },
  { value: 'secant_piling', label: 'Secant Piling' },
  { value: 'secondary_glazing', label: 'Secondary Glazing' },
  { value: 'security', label: 'Security' },
  { value: 'security_/_cctv', label: 'Security / CCTV' },
  { value: 'security_doors', label: 'Security Doors' },
  { value: 'security_fencing', label: 'Security Fencing' },
  { value: 'security_gates', label: 'Security Gates' },
  { value: 'setting_out_/_site_survey', label: 'Setting Out / Site Survey' },
  { value: 'sheet_piling', label: 'Sheet Piling' },
  { value: 'shop_fitting', label: 'Shop fitting' },
  { value: 'shopfronts', label: 'Shopfronts' },
  { value: 'shot_blasting', label: 'Shot Blasting' },
  { value: 'shower_screens', label: 'Shower Screens' },
  { value: 'shutters', label: 'Shutters' },
  { value: 'signage', label: 'Signage' },
  { value: 'silicone_/_mastic', label: 'Silicone / Mastic' },
  { value: 'single_ply_/_flat_roofing', label: 'Single Ply / Flat Roofing' },
  { value: 'site_management', label: 'Site Management' },
  { value: 'slate_/_tile_roofing', label: 'Slate / Tile Roofing' },
  { value: 'sliding_doors', label: 'Sliding Doors' },
  { value: 'soft_landscaping', label: 'Soft Landscaping' },
  { value: 'solar_shading_/_awnings', label: 'Solar Shading / Awnings' },
  { value: 'specialist_cleaners', label: 'Specialist Cleaners' },
  {
    value: 'specialist_medical_equipment',
    label: 'Specialist Medical Equipment',
  },
  { value: 'spiral_stairs', label: 'Spiral Stairs' },
  { value: 'splashbacks', label: 'Splashbacks' },
  { value: 'sports_recreation_areas', label: 'Sports Recreation Areas' },
  { value: 'spray_foam_insulation', label: 'Spray Foam Insulation' },
  { value: 'sprayed_concrete', label: 'Sprayed Concrete' },
  { value: 'sprinkler_system', label: 'Sprinkler System' },
  { value: 'stained_glass', label: 'Stained Glass' },
  {
    value: 'stairs_/_balustrading_/_handrails',
    label: 'Stairs / Balustrading / Handrails',
  },
  { value: 'steam_services', label: 'Steam Services' },
  {
    value: 'steel_/_metal_balustrades_/_handrails',
    label: 'Steel / Metal Balustrades / Handrails',
  },
  { value: 'steel_/_metal_stairs', label: 'Steel / Metal Stairs' },
  { value: 'steel_doors', label: 'Steel Doors' },
  {
    value: 'steel_framing_systems_(sfs)',
    label: 'Steel Framing Systems (SFS)',
  },
  { value: 'steel_windows', label: 'Steel Windows' },
  { value: 'steelwork_/_metalwork', label: 'Steelwork / Metalwork' },
  { value: 'steeplejack', label: 'Steeplejack' },
  {
    value: 'stick_system_curtain_walling',
    label: 'Stick System Curtain Walling',
  },
  {
    value: 'stone_/_marble_/_granite_polishing_and_restoration',
    label: 'Stone / Marble / Granite Polishing and Restoration',
  },
  { value: 'stone_carving', label: 'Stone Carving' },
  { value: 'stone_cladding', label: 'Stone Cladding' },
  { value: 'stone_flooring', label: 'Stone Flooring' },
  { value: 'stone_mason', label: 'Stone Mason' },
  { value: 'stone_repairs', label: 'Stone Repairs' },
  { value: 'street_furniture', label: 'Street Furniture' },
  { value: 'street_lighting', label: 'Street Lighting' },
  { value: 'structural_engineer', label: 'Structural Engineer' },
  {
    value: 'structural_glazing_structures',
    label: 'Structural Glazing Structures',
  },
  {
    value: 'structural_insulated_panels_(sips)',
    label: 'Structural Insulated Panels (SIPs)',
  },
  { value: 'structural_steelwork', label: 'Structural Steelwork' },
  { value: 'structures', label: 'Structures' },
  { value: 'stud_welding', label: 'Stud Welding' },
  { value: 'substation', label: 'Substation' },
  {
    value: 'surface_finishes_/_coatings',
    label: 'Surface Finishes / Coatings',
  },
  { value: 'sustainable_drainage', label: 'Sustainable Drainage' },
  {
    value: 'switchgear_and_transformers',
    label: 'Switchgear and Transformers',
  },
  { value: 'telephone_systems', label: 'Telephone Systems' },
  { value: 'temporary_cradles', label: 'Temporary Cradles' },
  { value: 'temporary_fencing', label: 'Temporary Fencing' },
  { value: 'temporary_hoists_/_lifts', label: 'Temporary Hoists / Lifts' },
  { value: 'temporary_site_services', label: 'Temporary Site Services' },
  {
    value: 'temporary_works_engineer_/_designer',
    label: 'Temporary Works Engineer / Designer',
  },
  { value: 'temporary_works_screening', label: 'Temporary Works Screening' },
  { value: 'tensile_structures', label: 'Tensile Structures' },
  { value: 'terrazzo_flooring', label: 'Terrazzo Flooring' },
  {
    value: 'timber_balustrades_/_handrails',
    label: 'Timber Balustrades / Handrails',
  },
  { value: 'timber_cladding', label: 'Timber Cladding' },
  { value: 'timber_doors', label: 'Timber Doors' },
  { value: 'timber_flooring', label: 'Timber Flooring' },
  { value: 'timber_frame_/_structures', label: 'Timber Frame / Structures' },
  { value: 'timber_frames', label: 'Timber Frames' },
  { value: 'timber_panelling', label: 'Timber Panelling' },
  { value: 'timber_repairs', label: 'Timber Repairs' },
  { value: 'timber_stairs', label: 'Timber Stairs' },
  { value: 'timber_windows', label: 'Timber Windows' },
  { value: 'topographical_survey', label: 'Topographical Survey' },
  { value: 'towel_rails', label: 'Towel Rails' },
  { value: 'trace_heating', label: 'Trace Heating' },
  { value: 'traction_lifts', label: 'Traction Lifts' },
  { value: 'traffic_management', label: 'Traffic Management' },
  { value: 'traffic_signalling', label: 'Traffic Signalling' },
  { value: 'tree_surgery_-_felling', label: 'Tree Surgery - Felling' },
  { value: 'tree_surgery_/_felling', label: 'Tree Surgery / Felling' },
  { value: 'underpinning', label: 'Underpinning' },
  { value: 'unitised_curtain_walling', label: 'Unitised Curtain Walling' },
  { value: 'upholsterer', label: 'Upholsterer' },
  { value: 'utility_mapping', label: 'Utility Mapping' },
  { value: 'venetian_plastering', label: 'Venetian Plastering' },
  { value: 'ventilation', label: 'Ventilation' },
  { value: 'vinyl_flooring', label: 'Vinyl Flooring' },
  { value: 'walk-on_glass', label: 'Walk-on Glass' },
  { value: 'wall_panelling', label: 'Wall Panelling' },
  { value: 'wallpapering', label: 'Wallpapering' },
  { value: 'washrooms', label: 'Washrooms' },
  { value: 'wastewater_treatment_works', label: 'Wastewater Treatment Works' },
  { value: 'water_heaters', label: 'Water Heaters' },
  { value: 'water_mains_installations', label: 'Water Mains Installations' },
  { value: 'water_testing', label: 'Water Testing' },
  { value: 'water_treatment_works', label: 'Water Treatment Works' },
  { value: 'waterproofing', label: 'Waterproofing' },
  { value: 'waterproofing_/_tanking', label: 'Waterproofing / Tanking' },
  { value: 'welding_contractors', label: 'Welding Contractors' },
  { value: 'wet_underfloor_heating', label: 'Wet Underfloor Heating' },
  { value: 'wet_and_dry_rot_treatments', label: 'Wet and Dry Rot Treatments' },
  { value: 'wifi_/_internet_connection', label: 'Wifi / Internet Connection' },
  {
    value: 'window_film_/_manifestation',
    label: 'Window Film / Manifestation',
  },
  {
    value: 'windows_refurbishment_/_repairs',
    label: 'Windows Refurbishment / Repairs',
  },
  {
    value: 'wired_/_wireless_networking',
    label: 'Wired / Wireless Networking',
  },
  { value: 'worktops_/_countertops', label: 'Worktops / Countertops' },
  { value: 'wrought_iron', label: 'Wrought Iron' },
  { value: 'zinc_roofing_/_cladding', label: 'Zinc Roofing / Cladding' },
  { value: 'upvc_doors', label: 'uPVC Doors' },
  { value: 'upvc_windows', label: 'uPVC Windows' },
].sort((a, b) => a.label.localeCompare(b.label));

export { PACKAGE_OPTIONS };
