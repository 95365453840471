import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useAuth } from '../context/AuthProvider';
import BackButton from '../components/BackButton';
import styles from '../components/loginStyles';
import { FEATURES } from '../constants';

const SignInPage = ({ redirect = 'dashboard' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { loggedIn, loading, redirectIfLoggedIn, login } = useAuth();

  useEffect(() => {
    redirectIfLoggedIn(redirect);
  }, [loggedIn, loading, redirectIfLoggedIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    const trimmedEmail = email.trim();
    if (!trimmedEmail) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      const result = await login(trimmedEmail, password);
      if (result?.error) {
        setErrors({ general: result.error });
      }
      setIsSubmitting(false);
    } else {
      setErrors(newErrors);
    }
  };

  // Redirect or display the login form based on authentication status
  if (loggedIn) {
    return null; // Avoid rendering the form if already logged in
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={styles.login}
    >
      <Box sx={styles.login.back}>
        <BackButton />
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography sx={styles.login.title}>
          Login {isSubmitting && <CircularProgress size={20} />}
        </Typography>

        {errors.general && (
          <Typography color="error" variant="body2" align="center">
            {errors.general}
          </Typography>
        )}

        <FormControl fullWidth>
          <InputLabel htmlFor="email" shrink>
            Email
          </InputLabel>
          <TextField
            id="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            autoComplete="email"
            sx={styles.login.form.muiInput}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="password" shrink>
            Password
          </InputLabel>
          <TextField
            id="password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            fullWidth
            autoComplete="current-password"
            sx={styles.login.form.muiInput}
          />
        </FormControl>

        <Link sx={styles.login.forgot} href="/password-reset">
          Forgot Password?
        </Link>

        <Button
          sx={styles.login.form.submit}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Signing in...' : 'Sign In'}
        </Button>
        {FEATURES.ENABLE_SIGNUP && (
          <Typography variant="body2" align="center">
            Or{' '}
            <Link sx={styles.login.signup} href="/sign-up" variant="body2">
              Sign up
            </Link>
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

SignInPage.propTypes = {
  redirect: PropTypes.string,
};

export default SignInPage;
