/**
 * Layout for any public pages outside of the dahboard sign up / terms etc
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/footer';

const PublicLayout = () => {
  return (
    <div id="public-layout">
      <Outlet />
      <Footer />
    </div>
  );
};

export default PublicLayout;
