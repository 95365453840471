import { API, APP, MESSAGES } from '../constants';
import { logger } from './logger';
import axios from 'axios';

// Helper function for making API requests
const makeRequest = (method, url, dataOrParams, options = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  logger().log_request(url, dataOrParams);
  return axios({
    method,
    url,
    ...(method === 'get' ? { params: dataOrParams } : { data: dataOrParams }),
    ...options,
    withCredentials: true, // This is important for Flask-Login session cookie
    headers,
  }).catch((error) => {
    logger().log(error, 'error');
    throw error;
  });
};

// Individual request methods
export const post = (url, data, options = {}) =>
  makeRequest('post', url, data, options);
export const get = (url, params = {}, options = {}) =>
  makeRequest('get', url, params, options);
export const put = (url, data, options = {}) =>
  makeRequest('put', url, data, options);
export const del = (url, options = {}) =>
  makeRequest('delete', url, null, options);

// API request wrapper
export const API_REQUEST = {
  post: (path, data, options) => post(API.url(path), data, options),
  get: (path, params, options) => get(API.url(path), params, options),
  put: (path, data, options) => put(API.url(path), data, options),
  delete: (path, options) => del(API.url(path), options),
  isSuccess: (status) => {
    return status >= 200 && status < 300;
  },
  handleError: (error) => {
    logger().log(error, 'error');
    let message = MESSAGES.DEFAULT_ERROR;
    try {
      if (error.response?.data?.message) {
        message = error.response.data.message;
      } else if (error.request?.response) {
        let res = JSON.parse(error.request.response);
        message = res.message || MESSAGES.DEFAULT_ERROR;
      }
    } catch (e) {
      message = MESSAGES.DEFAULT_ERROR;
    }
    return message;
  },
};

// APP request wrapper
export const APP_REQUEST = {
  post: (path, data, options) => post(APP.url(path), data, options),
  get: (path, params, options) => get(APP.url(path), params, options),
  redirect: (path) => {
    window.location.href = APP.url(path);
  },
  skipTo: (uri) => {
    const pathArray = window.location.href.split('/');
    window.location.href = window.location.href.replace(
      pathArray[pathArray.length - 1],
      uri
    );
  },
};
