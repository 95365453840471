/**
 * Page to Test Components in Isolation
 *
 */

import React from 'react';
import UploadModal from '../components/quote/uploadModal';
import { useState } from 'react';

const Test = () => {
  const [showUploadModal, setShowUploadModal] = useState(true);
  return (
    <div>
      <UploadModal show={showUploadModal} setShow={setShowUploadModal} />
    </div>
  );
};

export default Test;
