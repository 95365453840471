const styles = {
  login: {
    fontFamily: 'RooneySans',
    backgroundColor: { xs: '#fff', sm: '#526269' },
    minHeight: '100vh',
    paddingTop: { xs: '40px', sm: '0' },
    back: {
      fontFamily: 'RooneySans',
      '& .back-button': {
        borderRadius: '50%',
        border: '1px solid #4CC0AD',
        '& svg': {
          color: '#4CC0AD',
        },
      },
    },
    form: {
      fontFamily: 'RooneySans',
      width: '100%',
      maxWidth: '440px',
      margin: '0 auto',
      padding: '20px 40px',
      borderRadius: '8px',
      border: { xs: 0, sm: '1px solid #ccc' },
      backgroundColor: '#fff',
      submit: {
        fontFamily: 'RooneySans',
        backgroundColor: '#4CC0AD',
        color: '#fff',
        textTransform: 'none',
        padding: '10px 30px',
        height: '40px',
        margin: '0 auto',
        borderRadius: '20px',
        fontSize: '19px',
        width: { xs: '100%', sm: 'auto' },
        '&:hover': {
          backgroundColor: '#4CC0AD',
        },
      },
      label: {
        fontFamily: 'RooneySans',
        left: '-14px',
        color: '#08202A',
        fontWeight: 600,
      },
      muiInput: {
        fontFamily: 'RooneySans',
        mb: 3,
        mt: 2,
        '& .Mui-error': {
          marginLeft: '0',
        },
        input: {
          fontFamily: 'RooneySans',
          fontSize: '16px',
          padding: '10px 14px',
        },
      },
    },
    title: {
      fontFamily: 'RooneySans',
      fontSize: '22px',
      fontWeight: 600,
      color: '#4CC0AD',
      margin: '16px 0',
    },
    subtitle: {
      fontFamily: 'RooneySans',
      fontSize: '16px',
      fontWeight: 600,
      margin: '-20px 0 20px',
    },
    forgot: {
      fontFamily: 'RooneySans',
      color: '#4CC0AD',
      fontSize: '14px',
      fontWeight: 400,
      marginTop: '-30px',
      cursor: 'pointer',
      zIndex: 1,
    },
    signup: {
      fontFamily: 'RooneySans',
      color: '#4CC0AD',
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer',
    },
  },
};

export default styles;
