import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      {/*<div className="footer-content">*/}
      {/*  <a href="/terms" className="footer-link">*/}
      {/*    Terms of Use*/}
      {/*  </a>*/}
      {/*  <span className="footer-separator">|</span>*/}
      {/*  <a href="/privacy" className="footer-link">*/}
      {/*    Privacy Policy*/}
      {/*  </a>*/}
      {/*  <span className="footer-separator">|</span>*/}
      {/*  <a href="/resources" className="footer-link">*/}
      {/*    Resources*/}
      {/*  </a>*/}
      {/*  <span className="footer-separator">|</span>*/}
      {/*  <a href="/contact" className="footer-link">*/}
      {/*    Contact*/}
      {/*  </a>*/}
      {/*  <span className="footer-separator">|</span>*/}
      {/*  <a*/}
      {/*    href="https://c-link.com"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*    className="footer-link"*/}
      {/*  >*/}
      {/*    Powered by*/}
      {/*    <img*/}
      {/*      src="/c-link-logo.svg"*/}
      {/*      alt="C-Link Logo"*/}
      {/*      className="footer-logo"*/}
      {/*    />*/}
      {/*  </a>*/}
      {/*</div>*/}
    </footer>
  );
};

export default Footer;
