import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import FileUploader from './FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { API_REQUEST } from '../../helpers/request';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { logger } from '../../helpers/logger';
import { PACKAGE_OPTIONS } from '../../constants/trades';

const createPackageForProject = async (
  projectId,
  packageName,
  files,
  callback
) => {
  try {
    // Create package (package)
    const packageResponse = await API_REQUEST.post(
      `/projects/${projectId}/packages`,
      {
        name: packageName,
      }
    );
    const packageId = packageResponse.data.id;
    logger().log('Package Created: ' + packageId, 'info');

    // Create FormData for files upload
    const formData = new FormData();

    // Add JSON metadata
    const jsonData = {
      files: files.map((file) => ({
        file: {
          path: file.rawFile.name,
        },
        subcontractor: file.subcontractor,
      })),
    };
    formData.append('json', JSON.stringify(jsonData));

    // Add files with their original names as keys
    files.forEach((file) => {
      formData.append(file.rawFile.name, file.rawFile);
    });

    // Upload files
    const quotesResponse = await API_REQUEST.post(
      `/projects/${projectId}/packages/${packageId}/quotes`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ).then((response) => {
      logger().log('Quotes Created', 'info');
      callback(projectId, packageId, response.data);
      return response.data;
    });

    return quotesResponse.data;
  } catch (error) {
    logger().log(error, 'error');
    if (error.response?.status === 409) {
      throw {
        ...error,
        packageError: error.response.data.message || 'Package already exists',
      };
    }
    throw error;
  }
};

const UploadModal = ({
  show,
  setShow,
  existingProjectId,
  existingPackages,
}) => {
  const [submittingForm, setFormSubmitting] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({
    projectName: '',
    packages: '',
    files: '',
  });

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      files: '',
    }));
  }, [files]);

  const resetForm = () => {
    setErrors({
      projectName: '',
      packages: '',
      files: '',
      general: '',
    });
    setSelectedPackages([]);
    setFiles([]);
    if (!existingProjectId) {
      setProjectName('');
    }
    setFormSubmitting(false);
  };

  const handleClose = () => {
    resetForm();
    setShow(false);
  };

  // Fetch project name when existingProjectId is provided
  useEffect(() => {
    const fetchProjectName = async () => {
      if (existingProjectId) {
        setIsLoadingProject(true);
        try {
          const response = await API_REQUEST.get(
            `/projects/${existingProjectId}`
          );
          setProjectName(response.data.name);
        } catch (error) {
          logger().log(error, 'error');
          setErrors((prev) => ({
            ...prev,
            general: 'Failed to fetch project details',
          }));
        } finally {
          setIsLoadingProject(false);
        }
      } else {
        setProjectName('');
      }
    };

    fetchProjectName();
  }, [existingProjectId]);

  const handleSubmit = async () => {
    setFormSubmitting(true);

    const validationErrors = {
      packages:
        selectedPackages.length === 0
          ? 'Please select at least one package'
          : '',
      files: files.length === 0 ? 'Please upload at least one file' : '',
    };

    // Only validate project name if we're creating a new project
    if (!existingProjectId) {
      validationErrors.projectName =
        projectName === '' ? 'Project Name is required' : '';
    }

    const fileErrors = files.filter((file) => file.errors.length > 0);
    const filesWithoutSubcontractor = files.filter(
      (file) => !file.subcontractor
    );

    if (files.length < 2) {
      validationErrors.files = 'At least two quote files must be present';
    }

    // Check for duplicate subcontractor names
    const subcontractorNames = files.map((file) =>
      file.subcontractor.toLowerCase()
    );
    const hasDuplicates = subcontractorNames.some(
      (name, index) => subcontractorNames.indexOf(name) !== index
    );

    if (hasDuplicates) {
      validationErrors.files = 'Duplicate subcontractor names are not allowed';
    }

    if (filesWithoutSubcontractor.length > 0) {
      validationErrors.files =
        'All files must have a subcontractor. Please add subcontractors to all files';
    }

    if (fileErrors.length > 0) {
      validationErrors.files =
        'There is an issue with a file uploaded, please fix the issues and try again.';
    }

    const hasValidationErrors = Object.values(validationErrors).some(
      (error) => error !== ''
    );

    if (!hasValidationErrors) {
      try {
        const packageName = selectedPackages.map((pkg) => pkg.label).join(', ');

        const handleTenderAnalysis = (projectId, tenderId) => {
          return API_REQUEST.post(
            `/projects/${projectId}/packages/${tenderId}/tender_analysis`
          ).then(() => {
            window.location.href = `/processing/${projectId}/${tenderId}`;
          });
        };

        if (existingProjectId) {
          // Add package to existing project
          await createPackageForProject(
            existingProjectId,
            packageName,
            files,
            handleTenderAnalysis
          );
        } else {
          // Create new project and add package
          const projectResponse = await API_REQUEST.post('/projects', {
            name: projectName,
          });
          const projectId = projectResponse.data.id;
          logger().log('Project Created: ' + projectId, 'info');

          await createPackageForProject(
            projectId,
            packageName,
            files,
            handleTenderAnalysis
          );
        }
      } catch (error) {
        setFormSubmitting(false);
        if (error.packageError) {
          setErrors((prev) => ({
            ...prev,
            packages: error.packageError,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            general: 'Failed to process request. Please try again.',
          }));
        }
        logger().log(error, 'error');
      }
    } else {
      setErrors(validationErrors);
      setFormSubmitting(false);
    }
  };
  // Show loading state while fetching project details
  if (isLoadingProject) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="loading-spinner-container">
            <CircularProgress />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const filteredPackages = existingPackages
    ? PACKAGE_OPTIONS.filter((option) => {
        return !existingPackages.some((pkg) => {
          // Split package name into individual labels
          const existingLabels = pkg.name
            .split(',')
            .map((label) => label.trim());
          // Check if the current option label matches any of the existing labels
          return existingLabels.includes(option.label);
        });
      })
    : PACKAGE_OPTIONS;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {!submittingForm ? (
          <Modal.Body>
            <CloseIcon className="close-icon" onClick={handleClose} />
            <div id="project-data" className="input-container">
              <div className="project-data-item">
                <label>
                  Project Name <span className="required-field">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Project Name"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  disabled={!!existingProjectId}
                  className={existingProjectId ? 'disabled-input' : ''}
                />
              </div>
              {errors.projectName && (
                <p className="error">{errors.projectName}</p>
              )}
              <div className="project-data-item">
                <label>
                  Packages <span className="required-field">*</span>
                </label>
                <Select
                  isMulti
                  options={filteredPackages}
                  value={selectedPackages}
                  onChange={setSelectedPackages}
                  placeholder="Select packages..."
                  className="package-select"
                  classNamePrefix="select"
                />
              </div>
              {errors.packages && <p className="error">{errors.packages}</p>}
            </div>
            <FileUploader onFilesChange={setFiles} />
            {errors.files && <p className="error">{errors.files}</p>}
            {errors.general && <p className="error">{errors.general}</p>}
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="loading-spinner-container">
              <CircularProgress />
            </div>
          </Modal.Body>
        )}
        {!submittingForm ? (
          <Modal.Footer>
            <div className="modal-footer-buttons">
              <Button
                className="button empty-button"
                variant="primary"
                onClick={handleSubmit}
              >
                Upload
              </Button>
              <Button
                className="button green-button"
                variant="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
};

UploadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  existingProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  existingPackages: PropTypes.array,
};

export default UploadModal;
