import {
  Box,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

export const ProgressBar = ({ progress, status }) => {
  // Status message mapping
  const statusMessages = {
    PENDING: 'Queued for analysis...',
    STARTED: 'Analyzing data...',
    SUCCESS: 'Analysis complete!',
    FAILURE: 'Analysis failed',
    UNPROCESSABLE: 'Unable to process results',
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: 'rgba(255, 255, 255, 0.3)',
          borderRadius: '9999px',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: 4,
            borderRadius: '9999px',
            '& .MuiLinearProgress-bar': {
              bgcolor: '#2dd4bf',
              transition: 'transform 0.1s linear',
            },
          }}
        />
      </Box>

      {/* Percentage Text */}
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'white',
          fontSize: '0.875rem',
        }}
      >
        {Math.round(progress)}%
      </Typography>

      {/* Spinner and Text */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          mt: 1,
        }}
      >
        {status !== 'FAILURE' && status !== 'UNPROCESSABLE' && (
          <CircularProgress
            size={16}
            sx={{
              color: '#facc15',
            }}
          />
        )}
        <Typography
          variant="body2"
          sx={{
            color:
              status === 'FAILURE' || status === 'UNPROCESSABLE'
                ? '#ef4444'
                : '#facc15',
            fontSize: '0.875rem',
          }}
        >
          {statusMessages[status]}
        </Typography>
      </Box>
    </>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  status: PropTypes.oneOf([
    'PENDING',
    'STARTED',
    'SUCCESS',
    'FAILURE',
    'UNPROCESSABLE',
  ]).isRequired,
};
