import React from 'react';
import { FEATURES } from '../constants';
import { Coffee, HeartHandshake, Hourglass } from 'lucide-react';

const SignupDisabled = () => {
  return (
    <div
      id="sign-up-form"
      className="w-[300px] mx-auto relative top-[200px] bg-white p-5 rounded"
    >
      <div className="text-center mb-6">
        <Hourglass className="w-12 h-12 text-green-600 mx-auto mb-4 animate-pulse" />
        <h2 className="form-title text-xl font-semibold text-green-600 mb-2">
          Hold That Thought!
        </h2>
        <h3 className="text-gray-600 mb-4">
          We&apos;re Brewing Something Special
        </h3>
      </div>

      <div className="space-y-4 mb-6">
        <div className="flex items-center gap-3">
          <Coffee className="w-5 h-5 text-green-600 flex-shrink-0" />
          <p className="text-sm text-gray-600">
            Our team is putting the finishing touches on some amazing features.
          </p>
        </div>
        <div className="flex items-center gap-3">
          <HeartHandshake className="w-5 h-5 text-green-600 flex-shrink-0" />
          <p className="text-sm text-gray-600">Want early access?</p>
        </div>
      </div>

      <div className="text-center mb-6">
        <p className="text-gray-600 text-sm">
          Reach out to us at{' '}
          <a
            href={`mailto:${FEATURES.CONTACT_EMAIL}`}
            className="text-green-600 hover:text-green-700 font-medium underline"
          >
            {FEATURES.CONTACT_EMAIL}
          </a>{' '}
          and we&apos;ll get you started.
        </p>
      </div>

      <div className="h-rule">
        <hr />
      </div>

      <div className="sign-in-link text-center mt-4">
        Already have an account?{' '}
        <button
          onClick={() => (window.location.href = '/sign-in')}
          className="text-green-600 hover:text-green-700 font-medium underline"
          type="button"
        >
          Sign in
        </button>
      </div>
    </div>
  );
};

export default SignupDisabled;
