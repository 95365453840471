//Generic form for account management, i.e login, signup, reset password, etc.
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const AccountForm = ({
  title,
  onSubmit,
  submitButtonText,
  children,
  isSubmitting = false,
  error = null,
}) => {
  return (
    <div className="account-form">
      <div className="form-container">
        <h2 className="form-title green">
          {title} {isSubmitting && <CircularProgress size={20} />}
        </h2>

        {error && <p className="error">{error}</p>}

        <form onSubmit={onSubmit}>
          {children}

          <div className="button-container">
            <button
              className="button green-button"
              type="submit"
              disabled={isSubmitting}
            >
              {submitButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

AccountForm.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isSubmitting: PropTypes.bool,
  error: PropTypes.string,
};

export default AccountForm;
