import * as React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import Sidebar from './dashboard/sidebar';
//import Footer from '../components/footer';
import { useAuth } from '../context/AuthProvider';

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div id="dashboard-layout">
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="content-wrapper">
        <div
          className="content-header"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <div>
            <button className="button green-button" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
