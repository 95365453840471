import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from '@mui/material';

export const SupportDialog = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Contact Support</DialogTitle>
    <DialogContent>
      <Typography variant="body1" gutterBottom>
        Our support team is here to help you resolve any issues with your
        analysis.
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
        You can reach us through:
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2">
          • Email:{' '}
          <Link href="mailto:support@c-link.com">support@c-link.com</Link>
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Please include your Project ID: {window.location.pathname.split('/')[2]}{' '}
        and Tender ID: {window.location.pathname.split('/')[3]} in your
        communication.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

SupportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
