import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { API_REQUEST } from '../helpers/request';
import {
  SupportDialog,
  ErrorMessage,
  UnprocessableMessage,
  ProgressBar,
} from '../components/processing';
import SignUpForm from '../components/account/sign-up-form';
import SignInPage from '../routes/sign-in';
import { useAuth } from '../context/AuthProvider';

// Custom hook for managing tender analysis status
const useTenderAnalysis = (projectId, packageId, signedUp) => {
  const [status, setStatus] = useState('PENDING');
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(null);

  const calculateProgress = useCallback(
    (currentStatus, elapsedSeconds) => {
      if (currentStatus === 'PENDING') return 10;
      if (currentStatus === 'STARTED') {
        if (elapsedSeconds <= 30) {
          return Math.min(90, 10 + (80 * elapsedSeconds) / 30);
        } else {
          const secondPhaseElapsed = Math.min(elapsedSeconds - 30, 30);
          return Math.min(99, 90 + (9 * secondPhaseElapsed) / 30);
        }
      }
      if (['SUCCESS', 'FAILURE', 'UNPROCESSABLE'].includes(currentStatus))
        return 100;
      return progress;
    },
    [progress]
  );

  useEffect(() => {
    if (!signedUp) return;

    if (status === 'STARTED' && !startTime) {
      setStartTime(Date.now());
    }

    const progressInterval =
      status === 'STARTED'
        ? setInterval(() => {
            const elapsedSeconds = (Date.now() - startTime) / 1000;
            setProgress(calculateProgress(status, elapsedSeconds));
          }, 100)
        : null;

    return () => progressInterval && clearInterval(progressInterval);
  }, [status, startTime, calculateProgress, signedUp]);

  useEffect(() => {
    if (!signedUp) return;

    const checkStatus = async () => {
      try {
        const response = await API_REQUEST.get(
          `/projects/${projectId}/packages/${packageId}/tender_analysis`
        );
        const newStatus = response.data.status;
        if (newStatus !== status) {
          setStatus(newStatus);
          if (newStatus === 'PENDING') setProgress(10);
          if (newStatus === 'STARTED') {
            setStartTime(Date.now());
            setProgress(10);
          }
          if (newStatus === 'FAILURE') {
            setProgress(100);
            setError('The analysis process encountered an error.');
          }
          if (newStatus === 'UNPROCESSABLE') setProgress(100);
          if (newStatus === 'SUCCESS') setProgress(100);
        }
      } catch (err) {
        setError('Failed to check analysis status.');
        console.error('Status check failed:', err);
      }
    };

    checkStatus();
    const statusInterval = setInterval(() => {
      if (!['SUCCESS', 'FAILURE', 'UNPROCESSABLE'].includes(status)) {
        checkStatus();
      }
    }, 5000);

    return () => clearInterval(statusInterval);
  }, [projectId, packageId, status, signedUp]);

  if (!signedUp) {
    return { status: 'PENDING', error: null, progress: 0 };
  }

  return { status, error, progress };
};

const ProcessingPage = () => {
  const [isSignIn, setIsSignIn] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const { projectId, packageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, loading, setLoggedIn } = useAuth();
  useEffect(() => {
    if (loggedIn && !signedUp) {
      setSignedUp(true);
    }
  }, [loggedIn, signedUp]);

  const { status, error, progress } = useTenderAnalysis(
    projectId,
    packageId,
    signedUp
  );

  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  useEffect(() => {
    if (status === 'SUCCESS') {
      navigate(`/dashboard/project/${projectId}/package/${packageId}/result`);
    }
  }, [status, projectId, packageId, navigate]);

  const handleContactSupport = () => setSupportDialogOpen(true);
  const handleSignUpSuccess = async () => {
    setSignedUp(true);
    setLoggedIn(true);
    // Stay on the same page
    window.location.reload(); // This will trigger a fresh load with the new auth state
  };
  if (isSignIn) {
    return <SignInPage redirect={location.pathname} />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: '#475569',
      }}
    >
      {!signedUp && !loading && (
        <div id="sign-up-modal" className="modal-backdrop">
          <SignUpForm
            onSuccess={handleSignUpSuccess}
            subtitle="Your analysis has been queued. Sign up now to get your results."
            redirectPath={`/dashboard/project/${projectId}/package/${packageId}/processing`}
            onSignInClick={() => setIsSignIn(true)}
          />
        </div>
      )}
      {signedUp && (
        <Box sx={{ width: '320px', position: 'relative' }}>
          <ProgressBar progress={progress} status={status} />
          {error && (
            <ErrorMessage
              error={error}
              onContactSupport={handleContactSupport}
            />
          )}
          {status === 'UNPROCESSABLE' && (
            <UnprocessableMessage onContactSupport={handleContactSupport} />
          )}
        </Box>
      )}
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </Box>
  );
};

export default ProcessingPage;
