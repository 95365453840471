import PropTypes from 'prop-types';
import { Alert, Typography, Button } from '@mui/material';

// Unprocessable message component
export const UnprocessableMessage = ({ onContactSupport }) => (
  <Alert
    severity="warning"
    sx={{
      mt: 2,
      '& .MuiAlert-message': {
        color: '#000',
        width: '100%',
      },
    }}
    action={
      <Button
        color="warning"
        size="small"
        onClick={onContactSupport}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Contact Support
      </Button>
    }
  >
    <Typography variant="body2" gutterBottom>
      The analysis completed but the results cannot be processed.
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Our team can help investigate what went wrong.
    </Typography>
  </Alert>
);

UnprocessableMessage.propTypes = {
  onContactSupport: PropTypes.func.isRequired,
};
