import { useLocation, Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { LayoutDashboard, HelpCircle, Info, MessageSquare } from 'lucide-react';
import { proqouImages, FEATURES } from '../../constants/index';
import styles from '../../components/muiStyles';

const Sidebar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname.startsWith(path);

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', path: '/dashboard' },
    { icon: HelpCircle, label: 'FAQ`s', path: '/help-support' },
  ];

  const handleFeedback = () => {
    window.open(
      `mailto:${FEATURES.CONTACT_EMAIL}?subject=Smart%20Tender%20Feedback%20-%20Alpha%20Version`,
      '_blank'
    );
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-header">
        <Box className="dashboard-logo" sx={styles.navigation.logo}>
          <Typography
            className="dashboard-logo-text"
            sx={styles.navigation.logo.text}
          >
            <img
              src={`${proqouImages}/ai-round-logo.svg`}
              alt="Smart Tender AI"
              style={styles.navigation.logo.roundLogo}
            />
            Smart
            <Typography
              className="dashboard-logo-text-blue"
              component="span"
              sx={styles.navigation.logo.text.span}
            >
              {' '}
              Tender{' '}
            </Typography>
            AI <br />
            <img
              className="dashboard-powered"
              src={`${proqouImages}/c-link-powered.png`}
              alt="Smart Tender AI"
              style={styles.navigation.logo.powered}
            />
          </Typography>
        </Box>
      </div>

      <nav className="sidebar-nav">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`nav-item ${isActive(item.path) ? 'active' : ''}`}
          >
            <item.icon className="nav-icon" />
            <span>{item.label}</span>
          </Link>
        ))}

        <button onClick={handleFeedback} className="nav-item feedback-button">
          <MessageSquare className="nav-icon" />
          <span>Send Feedback</span>
          <div className="alpha-tag">ALPHA</div>
        </button>

        <a
          href="https://c-link.com"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-item"
        >
          <Info className="nav-icon" />
          <span>About C-Link</span>
        </a>
      </nav>
    </aside>
  );
};

export default Sidebar;
