import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FEATURES } from '../constants';
import BackButton from '../components/BackButton';
import styles from '../components/muiStyles';
import { proqouImages } from '../constants/index';

const HelpSupport = () => {
  const faqs = [
    {
      question: 'How does SmartTenderAI function?',
      answer:
        'SmartTenderAI is an advanced procurement analysis tool that streamlines the supplier quote evaluation process. Upload up to three quotes to receive comprehensive analysis, including supplier recommendations, variance identification and strategic insights. As an early access alpha release, we are actively enhancing the platform through frequent iterations based on user feedback.',
    },
    {
      question: 'Which file formats are supported?',
      answer:
        'Currently, we support PDF documents, Microsoft Excel files (.xlsx, .xls) and CSV formats. Our development team is actively working to expand compatibility with additional file types.',
    },
    {
      question: 'What is the typical processing duration?',
      answer:
        'Analysis completion typically ranges from 30 seconds to one minute. However, during periods of high system utilisation, processing times may be extended. We continuously monitor system performance to maintain optimal service levels whilst managing varying demand on our infrastructure.',
    },
    {
      question: 'What factors influence analysis accuracy?',
      answer:
        'The precision of our analysis correlates directly with the level of detail present in the uploaded quotes. For optimal results, we recommend using comprehensive quotations. Our current system generates exactly five key points per analysis section - whilst this standardised approach ensures consistency, we acknowledge that this fixed structure may occasionally prove either insufficient or excessive depending on your specific procurement scenario.',
    },
    {
      question: 'What are the associated costs?',
      answer:
        'During this alpha phase, SmartTenderAI is being offered at no cost to all users.',
    },
    {
      question: 'How do I reset my password?',
      answer:
        'To reset your password, navigate to the login page and select the "Forgot Password" option. You will receive detailed instructions via email to complete the password reset process.',
    },
  ];

  return (
    <Box sx={styles.page}>
      <Box sx={styles.helpSupport.back}>
        <BackButton />
      </Box>

      <Grid container sx={styles.limitWidth}>
        <Grid item xs={12} sx={styles.navigation}>
          <Box sx={styles.navigation.logo}>
            <Typography sx={styles.navigation.logo.text}>
              <img
                src={`${proqouImages}/ai-round-logo.svg`}
                alt="Smart Tender AI"
                style={styles.navigation.logo.roundLogo}
              />
              Smart Tender AI <br />
              <img
                src={`${proqouImages}/c-link-powered.png`}
                alt="Smart Tender AI"
                style={styles.navigation.logo.powered}
              />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={styles.helpSupport.container}>
          <Typography sx={styles.helpSupport.heading}>
            Help & Support
          </Typography>

          <Box>
            <Typography sx={styles.helpSupport.title}>
              Frequently Asked Questions
            </Typography>

            {faqs.map((faq, index) => (
              <Box key={index} className="faq-item">
                <Typography sx={styles.helpSupport.subtitle}>
                  {faq.question}
                </Typography>
                <Typography sx={styles.helpSupport.paragraph}>
                  {faq.answer}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box>
            <Typography sx={styles.helpSupport.title}>Contact Us</Typography>
            <Typography sx={styles.helpSupport.contact}>
              Need help? Email us at{' '}
              <Button
                sx={styles.helpSupport.contact}
                component={Link}
                to={`mailto:${FEATURES.CONTACT_EMAIL}`}
              >
                {FEATURES.CONTACT_EMAIL}
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HelpSupport;
