import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { CLARITY_ID, ENV } from '../constants';

const ClarityAnalytics = () => {
  useEffect(() => {
    // Only initialize in prod or uat
    if (ENV === 'prod' || ENV === 'uat') {
      clarity.init(CLARITY_ID);
    }
  }, []);

  return null;
};

export default ClarityAnalytics;
