import { ENV } from '../constants';

const isDiagnosticMode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('diagnose') === 'true';
};

export const logger = () => {
  const log = (object, mode = 'log') => {
    if (mode === 'error') {
      console.error(object);
      // TODO: Send to server | log persistently
    }

    if (ENV === 'development' || isDiagnosticMode()) {
      if (mode === 'log') {
        console.log(object);
      } else if (mode === 'info') {
        console.info(object);
      } else if (mode === 'debug') {
        console.debug(object);
      }
    }
  };

  return {
    log_request: (url, payload) => {
      log('Request URL: ' + url, payload);
    },
    log: (object, mode = 'log') => {
      log(object, mode);
    },
  };
};
