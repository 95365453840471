import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import AccountForm from '../components/form/account';
import { CheckCircle, Mail, ArrowLeft } from 'lucide-react';
import PropTypes from 'prop-types';
import {
  isValidEmail,
  isValidPassword,
  validPasswordConditions,
} from '../helpers/validator';
import { API_REQUEST } from '../helpers/request';
import { FEATURES } from '../constants';

const PasswordResetPage = ({ isActivation }) => {
  const params = useParams();
  const navigate = useNavigate();
  const token = params && params.token;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [countdown, setCountdown] = useState(5);

  const { loggedIn } = useAuth();

  if (loggedIn) {
    return null;
  }

  const getPageContent = () => ({
    title: isActivation ? 'Activate Account' : 'Reset Password',
    successTitle: isActivation ? 'Account Activated' : 'Password Reset',
    emailSentMessage: isActivation
      ? "We've sent account activation instructions to:"
      : "We've sent password reset instructions to:",
    instructions: isActivation
      ? 'Please check your email and follow the link to activate your account. The link will expire in 24 hours.'
      : 'Please check your email and follow the link to reset your password. The link will expire in 24 hours.',
    submitButtonText: isActivation ? 'Activate Account' : 'Reset Password',
    successMessage: isActivation
      ? 'Your account has been successfully activated'
      : 'Password has been successfully reset',
  });

  const pageContent = getPageContent();

  const handleSubmitRequest = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const trimmedEmail = email.trim();
    if (!isValidEmail(trimmedEmail)) {
      setError('Invalid email');
      setIsSubmitting(false);
      return;
    }

    if (success || isSubmitting) {
      setIsSubmitting(false);
      return;
    }

    API_REQUEST.post('/password-reset/request', { email: trimmedEmail })
      .then((res) => {
        const { data } = res;
        setMessage(
          data.message ||
            `If a user with that email exists, a ${isActivation ? 'activation' : 'password reset'} email will be sent.`
        );
        setIsSubmitting(false);
        setError(null);
        setSuccess(true);
      })
      .catch(() => {
        setError(
          <>
            An error occurred. Please contact support at{' '}
            <a
              href={`mailto:${FEATURES.CONTACT_EMAIL}`}
              className="text-green-600 hover:text-green-700 font-medium underline"
            >
              {FEATURES.CONTACT_EMAIL}
            </a>
            .
          </>
        );
        setIsSubmitting(false);
      });
  };

  const handleBackToLogin = () => {
    navigate('/sign-in');
  };

  const renderSuccessMessage = () => (
    <div className="account-form">
      <div className="reset-success-container">
        <div className="success-icon">
          <Mail size={48} className="text-green-500" />
        </div>
        <h2 className="form-title green">Check Your Email</h2>
        <div className="success-description">
          <p>{pageContent.emailSentMessage}</p>
          <p className="email-sent">{email}</p>
          <p className="instructions">{pageContent.instructions}</p>
          <p className="spam-note">
            Don&apos;t see the email? Check your spam folder.
          </p>
        </div>
        <div className="button-container">
          <button
            onClick={() => setSuccess(false)}
            className="button green-button mb-3"
            type="button"
          >
            Resend Email
          </button>
          <button
            onClick={handleBackToLogin}
            className="back-to-login"
            type="button"
          >
            <ArrowLeft size={16} />
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );

  const renderPasswordResetSuccess = () => (
    <div className="account-form">
      <div className="reset-success-container">
        <div className="success-icon">
          <CheckCircle size={48} className="text-green-500" />
        </div>
        <h2 className="form-title green">{message}</h2>
        <p className="success-description">
          You will be redirected to the login page in {countdown} seconds
        </p>
        <div className="button-container">
          <button
            onClick={handleSignIn}
            className="button green-button"
            type="button"
          >
            Sign In Now
          </button>
        </div>
      </div>
    </div>
  );
  //Submit a password reset
  const handleSubmitReset = (e) => {
    e.preventDefault();

    if (!isValidPassword(password)) {
      setError(validPasswordConditions);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsSubmitting(true);

    API_REQUEST.post('/password-reset/confirm', {
      token,
      new_password: password,
    })
      .then((res) => {
        const { data } = res;
        setIsSubmitting(false);
        setError(null);
        setSuccess(true);
        setMessage(data.message || pageContent.successMessage);

        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              navigate('/sign-in');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      })
      .catch(() => {
        setError(
          `An error occurred while ${isActivation ? 'activating your account' : 'resetting your password'}. Please contact support at ${FEATURES.CONTACT_EMAIL}.`
        );
        setIsSubmitting(false);
      });
  };

  const setEmailAndClearError = (e) => {
    //Clear the error and success messages if the email changes
    setEmail(e.target.value.trim());
    setError(null);
    setSuccess(false);
    setIsSubmitting(false);
  };

  const handleSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <div id="reset-password-page" className="account-form-page">
      {!token && !success && (
        <AccountForm
          title={pageContent.title}
          onSubmit={handleSubmitRequest}
          submitButtonText={pageContent.submitButtonText}
          isSubmitting={isSubmitting}
          error={error}
        >
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              value={email}
              onChange={setEmailAndClearError}
              type="email"
              id="email"
              autoComplete="email"
            />
            <a className="forgot-password green" href="/sign-in">
              Go back to Login
            </a>
          </div>
        </AccountForm>
      )}
      {!token && success && renderSuccessMessage()}
      {token && !success && (
        <AccountForm
          title={pageContent.title}
          onSubmit={handleSubmitReset}
          submitButtonText={pageContent.submitButtonText}
          isSubmitting={isSubmitting}
          error={error}
        >
          <div className="input-container">
            <label htmlFor="password">Password:</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              autoComplete="new-password"
            />
          </div>

          <div className="input-container">
            <label htmlFor="confirm-password">Confirm Password:</label>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              id="confirm-password"
              autoComplete="new-password"
            />
            <a className="forgot-password green" href="/sign-in">
              Go back to Login
            </a>
          </div>
        </AccountForm>
      )}
      {token && success && renderPasswordResetSuccess()}
    </div>
  );
};

PasswordResetPage.propTypes = {
  isActivation: PropTypes.bool.isRequired,
};

export default PasswordResetPage;
