import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useAuth } from '../../context/AuthProvider';
import {
  isValidEmail,
  isValidPassword,
  validPasswordConditions,
} from '../../helpers/validator';
import styles from '../loginStyles';

const SignUpForm = ({
  onSuccess,
  title = 'Sign Up Now!',
  subtitle = 'Sign up for updates and new feature announcements',
  onSignInClick,
  redirectPath = 'dashboard',
}) => {
  const { register } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptNewsletter, setAcceptNewsletter] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setErrors({});

    // Ensure email is trimmed
    const trimmedEmail = email.trim();
    setEmail(trimmedEmail);

    // Validate all fields
    const newErrors = {};

    if (!trimmedEmail) newErrors.email = 'Email is required';
    else if (!isValidEmail(trimmedEmail))
      newErrors.email = 'Invalid email address';

    if (!isValidPassword(password)) {
      newErrors.password = validPasswordConditions;
    }

    if (!acceptTerms) {
      newErrors.terms = 'You must accept the terms and conditions';
    }

    // If there are any validation errors, stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Proceed with submission only if validation passed
    try {
      setLoading(true);
      const result = await register(
        trimmedEmail,
        password,
        acceptNewsletter,
        redirectPath
      );

      if (result.success) {
        if (result.warning) {
          console.warn('Registration warning:', result.warning);
        }
        onSuccess(result);
      } else {
        setErrors({ submit: result.error });
      }
    } catch (error) {
      setErrors({
        submit:
          error.response?.data?.message ||
          'An unexpected error occurred during registration',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={styles.login}
    >
      {loading && (
        <div className="load-overlay">
          <CircularProgress />
        </div>
      )}

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography sx={styles.login.title}>{title}</Typography>

        <Typography sx={styles.login.subtitle}>{subtitle}</Typography>

        <FormControl fullWidth>
          <InputLabel htmlFor="email" shrink>
            Email:
          </InputLabel>
          <TextField
            id="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            autoComplete="email"
            placeholder="Enter your email"
            sx={styles.login.form.muiInput}
            disabled={loading}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="password" shrink>
            Password
          </InputLabel>
          <TextField
            id="password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            error={!!errors.password}
            helperText={errors.password}
            fullWidth
            placeholder="Enter your password"
            autoComplete="new-password"
            sx={styles.login.form.muiInput}
          />
        </FormControl>

        <div className="form-checkbox">
          <label>
            <input
              name="terms"
              type="checkbox"
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
              disabled={loading}
            />
            I accept the terms and conditions
          </label>
          {errors.terms && <p className="error">{errors.terms}</p>}
        </div>

        <div className="form-checkbox">
          <label>
            <input
              name="newsletter"
              type="checkbox"
              checked={acceptNewsletter}
              onChange={(e) => setAcceptNewsletter(e.target.checked)}
              disabled={loading}
            />
            Sign up for updates and new feature announcements
          </label>
        </div>

        <Grid
          container
          flexDirection="column"
          alignItems="center"
          sx={{ position: 'relative' }}
        >
          <Button
            sx={styles.login.form.submit}
            type="submit"
            disabled={loading}
          >
            {loading ? 'Signing Up...' : 'Sign Up'}
          </Button>

          {errors.submit && <p className="error">{errors.submit}</p>}
        </Grid>

        <Typography variant="body2" align="center">
          Already have an account?{' '}
          <Link
            onClick={onSignInClick}
            sx={styles.login.signup}
            disabled={loading}
          >
            Sign in
          </Link>
        </Typography>

        <div className="social-login-container">
          {/**To Do Add in Social Login **/}
        </div>
      </Box>
    </Grid>
  );
};

SignUpForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onSignInClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  redirectPath: PropTypes.string,
};

export default SignUpForm;
