import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_REQUEST } from '../helpers/request';
import UploadModal from '../components/quote/uploadModal';
import { ChevronDown, ChevronRight, Package, Plus, Upload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const STATUS_COLORS = {
  PENDING: '#FDB022', // Yellow
  STARTED: '#FDB022', // Yellow
  FAILURE: '#F04438', // Red
  SUCCESS: '#12B76A', // Green
  UNPROCESSABLE: '#F04438', // Red
};

const EmptyState = ({ onUploadClick }) => (
  <div className="empty-state-container">
    <div className="empty-state">
      <div className="upload-icon">
        <Upload size={48} strokeWidth={1} />
      </div>
      <h3>You don&apos;t have any quotes yet in this project</h3>
      <p>Upload up to 3 documents today</p>
      <button onClick={onUploadClick} className="upload-button">
        UPLOAD QUOTES NOW
      </button>
    </div>
  </div>
);

EmptyState.propTypes = {
  onUploadClick: PropTypes.func.isRequired,
};

const ProjectItem = ({ project, onAddPackage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageDetails, setPackageDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState({});
  const [tenderStatus, setTenderStatus] = useState({});
  const navigate = useNavigate();

  // Add new function to fetch tender analysis status
  const fetchTenderAnalysis = async (packageId) => {
    try {
      const response = await API_REQUEST.get(
        `/projects/${project.id}/packages/${packageId}/tender_analysis`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        setTenderStatus((prev) => ({
          ...prev,
          [packageId]: response.data.status,
        }));
      }
    } catch (error) {
      console.error('Error fetching tender analysis:', error);
      setTenderStatus((prev) => ({
        ...prev,
        [packageId]: 'FAILURE',
      }));
    }
  };

  const fetchPackageDetails = async (packageId) => {
    if (isLoadingQuotes[packageId]) return;

    setIsLoadingQuotes((prev) => ({ ...prev, [packageId]: true }));
    try {
      const response = await API_REQUEST.get(
        `/projects/${project.id}/packages/${packageId}/quotes`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        const quotes = response.data;
        const uniqueSubcontractors = new Set(
          quotes.map((quote) => quote.subcontractor_name)
        );
        setPackageDetails((prev) => ({
          ...prev,
          [packageId]: {
            subcontractorCount: uniqueSubcontractors.size,
          },
        }));
      }
      await fetchTenderAnalysis(packageId);
    } catch (error) {
      console.error('Error fetching package details:', error);
    } finally {
      setIsLoadingQuotes((prev) => ({ ...prev, [packageId]: false }));
    }
  };
  const fetchPackages = async () => {
    try {
      setIsLoading(true);
      const response = await API_REQUEST.get(
        `/projects/${project.id}/packages`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        setPackages(response.data);
      }
    } catch (error) {
      console.error('Error fetching packages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch packages immediately when component mounts
    fetchPackages();
  }, [project.id]);

  useEffect(() => {
    // Fetch quotes for all packages when project is expanded
    if (isExpanded && packages.length > 0) {
      packages.forEach((pck) => {
        // Only fetch if we don't have the details yet
        if (!packageDetails[pck.id]) {
          fetchPackageDetails(pck.id);
        }
      });
    }
  }, [isExpanded, packages]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handlePackageClick = (packageId) => {
    navigate(`/dashboard/project/${project.id}/package/${packageId}/result`);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  return (
    <div className="project-item">
      <div className="project-header" onClick={handleToggle}>
        <div className="project-title">
          {isExpanded ? (
            <ChevronDown className="chevron-icon" />
          ) : (
            <ChevronRight className="chevron-icon" />
          )}
          <span className="project-name">{project.name}</span>
          <span className="package-count">
            {packages.length} Package{packages.length !== 1 ? 's' : ''}
          </span>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onAddPackage(project.id, packages);
          }}
          className="add-package-button"
        >
          <Plus className="plus-icon" />
          <span>Add Package</span>
        </button>
      </div>

      <div className={`project-content ${isExpanded ? 'expanded' : ''}`}>
        {isLoading ? (
          <div className="loading">Loading packages...</div>
        ) : packages.length > 0 ? (
          <div className="packages-list">
            {packages.map((pck) => (
              <div
                key={pck.id}
                className="package-item"
                onClick={() => handlePackageClick(pck.id)}
              >
                <div className="package-info">
                  <Package className="package-icon" />
                  <div className="package-details">
                    <h4>{pck.name}</h4>
                    <div className="package-meta">
                      <span>{formatDate(pck.created)}</span>
                    </div>
                  </div>
                </div>
                <div className="package-metrics">
                  <div className="status-indicator">
                    <span className="duration">
                      {packageDetails[pck.id]?.subcontractorCount || 0}{' '}
                      subcontractor
                      {(packageDetails[pck.id]?.subcontractorCount || 0) !== 1
                        ? 's'
                        : ''}
                    </span>
                    {tenderStatus[pck.id] && (
                      <span
                        className="status-circle"
                        style={{
                          backgroundColor: STATUS_COLORS[tenderStatus[pck.id]],
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-packages">No packages found in this project</div>
        )}
      </div>
    </div>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onAddPackage: PropTypes.func.isRequired,
};

export default function DashboardPage() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await API_REQUEST.get('/projects');
        if (API_REQUEST.isSuccess(response.status)) {
          setProjects(response.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleAddPackage = (projectId, packages) => {
    setSelectedProjectId(Number(projectId));
    setSelectedPackages(packages);
    setShowUploadModal(true);
  };

  const handleCloseModal = () => {
    setShowUploadModal(false);
    setSelectedProjectId(null);
    setSelectedPackages([]);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <div className="header-content">
          <h1>Projects</h1>
          <p>
            You have {projects.length} project{projects.length !== 1 ? 's' : ''}
          </p>
        </div>
        <button
          onClick={() => {
            setSelectedProjectId(null);
            setSelectedPackages([]);
            setShowUploadModal(true);
          }}
          className="new-button"
        >
          <Plus className="plus-icon" />
          <span>New</span>
        </button>
      </div>

      {isLoading ? (
        <div className="loading-state">
          <div className="spinner"></div>
          <p>Loading projects...</p>
        </div>
      ) : projects.length === 0 ? (
        <EmptyState
          onUploadClick={() => {
            setSelectedProjectId(null);
            setSelectedPackages([]);
            setShowUploadModal(true);
          }}
        />
      ) : (
        <div className="projects-list">
          {projects.map((project) => (
            <ProjectItem
              key={project.id}
              project={project}
              onAddPackage={handleAddPackage}
            />
          ))}
        </div>
      )}

      <UploadModal
        show={showUploadModal}
        setShow={handleCloseModal}
        existingProjectId={selectedProjectId}
        existingPackages={selectedPackages}
      />
    </div>
  );
}
