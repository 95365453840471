export const FILE_UPLOAD_STATES = {
  INITIAL: 1,
  UPLOADING: 2,
  PROCESSING: 3,
  PROCESSED: 4,
  ERROR: 5,
};

const app_host = process.env.REACT_APP_HOST;
const api_host = process.env.REACT_APP_API_HOST;
export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const CLARITY_ID = process.env.REACT_APP_CLARITY_ID;

export const FEATURES = {
  ENABLE_SIGNUP: process.env.REACT_APP_ENABLE_SIGNUP === 'true',
  CONTACT_EMAIL: process.env.REACT_APP_CONTACT_EMAIL,
};

if (ENV === 'development') {
  console.log(
    'ENVIRONMENT:',
    `app_host=${app_host}, api_host=${api_host}, ENABLE_SIGNUP=${FEATURES.ENABLE_SIGNUP}`
  );
}

export const MESSAGES = {
  DEFAULT_ERROR: 'Something went wrong',
};

const concatPath = (base, path) => {
  // Strip trailing slash from base and leading slash from path
  return base.replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '');
};

export const API = {
  host: api_host,
  url: (path) => {
    return concatPath(api_host, path);
  },
};

export const APP = {
  host: app_host,
  url: (path) => {
    return concatPath(app_host, path);
  },
};

export const proqouImages =
  'https://clink-assets.s3.eu-west-2.amazonaws.com/production/proquo';
