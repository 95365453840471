import PropTypes from 'prop-types';
import { Alert, Typography, Button } from '@mui/material';

// Error message components with support button
export const ErrorMessage = ({ error, onContactSupport }) => (
  <Alert
    severity="error"
    sx={{
      mt: 2,
      '& .MuiAlert-message': {
        color: '#000',
        width: '100%',
      },
    }}
    action={
      <Button
        color="error"
        size="small"
        onClick={onContactSupport}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Contact Support
      </Button>
    }
  >
    <Typography variant="body2" gutterBottom>
      {error}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Reference ID: {Date.now().toString(36)}
    </Typography>
  </Alert>
);

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
  onContactSupport: PropTypes.func.isRequired,
};
