import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadModal from '../components/quote/uploadModal';
import styles from '../components/muiStyles';
import { proqouImages } from '../constants/index';

const IntroPage = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <Box sx={styles.page}>
      <UploadModal show={showUploadModal} setShow={setShowUploadModal} />
      <Grid container>
        {/* Navigation Section */}
        <Grid item xs={12} sx={styles.navigation}>
          <Box sx={styles.navigation.logo}>
            <Typography sx={styles.navigation.logo.text}>
              <img
                src={`${proqouImages}/ai-round-logo.svg`}
                alt="Smart Tender AI"
                style={styles.navigation.logo.roundLogo}
              />
              Smart
              <Typography
                component="span"
                sx={styles.navigation.logo.text.span}
              >
                {' '}
                Tender{' '}
              </Typography>
              AI <br />
              <img
                src={`${proqouImages}/c-link-powered.png`}
                alt="Smart Tender AI"
                style={styles.navigation.logo.powered}
              />
            </Typography>
          </Box>
          <Box
            sx={{ ...styles.navigation.login, ...styles.navigation.limitWidth }}
          >
            <Button
              variant="contained"
              sx={{ ...styles.button, ...styles.button.login }}
              component={Link}
              to="/sign-in"
            >
              Login
            </Button>
            <Button
              variant="contained"
              sx={{ ...styles.button, ...styles.button.signup }}
              component={Link}
              to="/sign-up"
            >
              Sign up
            </Button>
          </Box>
        </Grid>

        {/* Header Section */}
        <Grid item xs={12} sx={styles.header}>
          <Typography variant="h1" sx={styles.header.h1}>
            Simplify Supplier Quotes with <br />
            <Typography component="span" sx={styles.header.h1.span}>
              {' '}
              AI-Powered{' '}
            </Typography>
            Insights
          </Typography>
          <Typography variant="h2" sx={styles.header.h2}>
            Upload up to 3 quotes and get an instant analysis of the best
            supplier, <br /> discrepancies and actionable insights <br />
            <Typography component="span" sx={styles.header.h1.span}>
              all in a few clicks.
            </Typography>
          </Typography>
          <Button
            variant="contained"
            className="page-button"
            sx={{ ...styles.button, ...styles.button.signup }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload your quotes now
          </Button>
        </Grid>

        {/* How It Works Section */}
        <Grid item sx={styles.works} xs={12}>
          <Box sx={styles.limitWidth}>
            <Typography sx={styles.works.title}>How it works</Typography>
            <Grid container sx={styles.howItWorksIcons}>
              {[
                {
                  title: 'Upload Quotes',
                  description:
                    'Drag and drop your quotes or select up to 3 quotes',
                },
                {
                  title: 'AI Driven Analysis',
                  description:
                    'Get a detailed comparison and find discrepancies',
                },
                {
                  title: 'Take Action',
                  description:
                    'Send requests for clarification or better pricing',
                },
              ].map((entry, index) => (
                <Grid xs={12} lg={4} key={index} sx={styles.icon}>
                  <img
                    className={`icon-${index + 1}`}
                    src={`${proqouImages}/how-${index + 1}.svg`}
                    alt={entry.title}
                    style={styles.icon.img}
                  />
                  <Typography variant="h6" sx={styles.icon.title}>
                    {entry.title}
                  </Typography>
                  <Typography variant="body2" sx={styles.icon.description}>
                    {entry.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Unlock Section */}
        <Grid sx={styles.unlock} item xs={12}>
          <Box sx={styles.limitWidth}>
            <Typography sx={styles.unlock.title} variant="h3">
              Unlock Powerful{' '}
              <Typography component="span">AI-Driven Tools </Typography>
              for Smarter Decisions
            </Typography>

            <Grid container>
              <Grid item xs={12} md={6} p={1}>
                <img
                  style={styles.unlock.image}
                  src={`${proqouImages}/unlock-1.svg`}
                  alt="Why Choose SmartTenderAI"
                />
              </Grid>
              <Grid sx={styles.unlock.textSection} item xs={12} md={6}>
                <Typography sx={styles.unlock.textSection.title}>
                  Best Supplier Comparison
                </Typography>
                <Typography sx={styles.unlock.textSection.subtitle}>
                  See at a glance which supplier offers the best overall deal.
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={styles.unlock.rowReverse}>
              <Grid item xs={12} md={6}>
                <Typography
                  className="blue"
                  sx={styles.unlock.textSection.title}
                >
                  Spot Discrepancies Instantly
                </Typography>
                <Typography sx={styles.unlock.textSection.subtitle}>
                  Change supplier to Subcontractor
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  style={styles.unlock.image}
                  src={`${proqouImages}/unlock-2.svg`}
                  alt="Why Choose SmartTenderAI"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <img
                  style={styles.unlock.image}
                  src={`${proqouImages}/unlock-3.svg`}
                  alt="Why Choose SmartTenderAI"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className="orange"
                  sx={styles.unlock.textSection.title}
                >
                  Best Supplier Comparison
                </Typography>
                <Typography sx={styles.unlock.textSection.subtitle}>
                  See at a glance which supplier offers the best overall deal.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Why Choose Section */}
        <Grid item xs={12} sx={styles.choose}>
          <Box sx={styles.limitWidth}>
            <Grid container>
              <Grid item lg={5} xs={12}>
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src={`${proqouImages}/ai-video.svg`}
                  alt="Why Choose SmartTenderAI"
                />
              </Grid>
              <Grid item mt={5} lg={7} xs={12}>
                <Box>
                  <Typography sx={styles.choose.title}>
                    Why Choose{' '}
                    <Typography component="span" sx={styles.choose.title.span}>
                      Smart Tender AI
                    </Typography>
                  </Typography>
                  <Box sx={styles.choose.list} component="ul">
                    <Typography sx={styles.choose.list.listItem} component="li">
                      <CheckCircleIcon sx={styles.choose.list.checkmark} />
                      Streamline the supplier quote review process.
                    </Typography>
                    <Typography sx={styles.choose.list.listItem} component="li">
                      <CheckCircleIcon sx={styles.choose.list.checkmark} />
                      Identify and highlight any discrepancies between quotes.
                    </Typography>
                    <Typography sx={styles.choose.list.listItem} component="li">
                      <CheckCircleIcon sx={styles.choose.list.checkmark} />
                      Easily request clarification or better pricing from
                      suppliers.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Reference Section */}
        <Grid sx={styles.references} item xs={12}>
          <Box sx={styles.limitWidth}>
            <Grid container>
              <Grid sx={styles.references.item} item xs={12} sm={6} md={4}>
                <Typography sx={styles.references.title}>
                  30
                  <Typography
                    sx={styles.references.title.span}
                    component="span"
                  >
                    days
                  </Typography>
                  <Typography sx={styles.references.subtitle}>
                    Full Implementation
                  </Typography>
                </Typography>
              </Grid>
              <Grid sx={styles.references.item} item xs={12} sm={6} md={4}>
                <Typography sx={styles.references.title}>
                  53
                  <Typography
                    sx={styles.references.title.span}
                    component="span"
                  >
                    % less
                  </Typography>
                  <Typography sx={styles.references.subtitle}>
                    Time Spent Procuring Packages
                  </Typography>
                </Typography>
              </Grid>
              <Grid sx={styles.references.item} item xs={12} sm={6} md={4}>
                <Typography sx={styles.references.title}>
                  +6500
                  <Typography sx={styles.references.subtitle}>
                    Subcontractors In Our Database
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Download Section */}
        <Grid item xs={12} className="download-section" sx={styles.header}>
          <Typography sx={styles.header.h1}>
            Ready to
            <Typography component="span" sx={styles.header.h1.span}>
              {' '}
              Download{' '}
            </Typography>
            Your
            <Typography component="span" sx={styles.header.h1.span}>
              {' '}
              Reports?
            </Typography>
          </Typography>
          <Typography className="limited-width" sx={styles.header.h2}>
            Enjoy full access to download AI-generated summaries by registering
            for free.
            <Typography component="span" sx={styles.header.h2.span}>
              {' '}
              No hidden fees or subscriptions.
            </Typography>
          </Typography>
          <Button
            className="page-button"
            variant="contained"
            sx={{ ...styles.button, ...styles.button.signup }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload your quotes now
          </Button>
        </Grid>

        {/* FAQ Section */}
        <Grid sx={styles.faq} item xs={12}>
          <Box sx={styles.limitWidth}>
            <Typography sx={styles.faq.heading}>FAQ</Typography>
            {[
              {
                question: 'How does the AI work?',
                answer:
                  'SmartTenderAI is an AI-powered tool that simplifies supplier quote review. It allows you to upload up to 3 quotes and get an instant analysis of the best supplier, discrepancies, and actionable insights.',
              },
              {
                question: 'What type of files can I upload?',
                answer:
                  'We accept PDF, Excel files (.xlsx, .xls), and CSV files. We are working on supporting additional file types.',
              },
              {
                question: 'How long does it take to get the results?',
                answer:
                  'Processing time varies from 30 seconds to 1 minute, depending on the number of quotes and the size of the files.',
              },
              {
                question: 'Is there a cost?',
                answer: 'No, there is no cost to use SmartTenderAI.',
              },
            ].map((faq, index) => (
              <Box key={index} mt={2}>
                <Typography sx={styles.faq.title}>{faq.question}</Typography>
                <Typography sx={styles.faq.subtitle}>{faq.answer}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid sx={styles.imageSection} item xs={12}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={`${proqouImages}/ai-bottom.svg`}
            alt="Why Choose SmartTenderAI"
          />
        </Grid>

        {/* Download Section */}
        <Grid item xs={12} className="download-section" sx={styles.header}>
          <Typography sx={styles.header.h1}>
            Start by
            <Typography component="span" sx={styles.header.h1.span}>
              {' '}
              Uploading{' '}
            </Typography>
            Your Quotes
            <Typography component="span" sx={styles.header.h1.span}>
              {' '}
              Now
            </Typography>
          </Typography>
          <br />
          <Button
            className="page-button"
            variant="contained"
            component={Link}
            to="/sign-in"
            sx={{ ...styles.button, ...styles.button.login }}
          >
            Sign In
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntroPage;
