import { Outlet } from 'react-router-dom';
import { AuthProvider } from '../context/AuthProvider';
import ClarityAnalytics from '../context/ClarityAnalytics';

const RootLayout = () => {
  return (
    <AuthProvider>
      <ClarityAnalytics />
      <main>
        <Outlet />
      </main>
    </AuthProvider>
  );
};

export default RootLayout;
